<template>
    <div class="detail-container">
        <div class="paper-title">
            {{paperInfo.theory_test_paper_name}}
            <el-button class="backBtn" type="primary" @click="goBack">返回</el-button>
        </div>
        <template v-if="Object.keys(paperInfo.theory_topic_data).length !== 0">
            <PaperDetail :paperDetail.sync="paperInfo.theory_topic_data"/>
        </template>
    </div>
</template>

<script>
    import {trainTheoryDetailsTeacher} from '@/utils/apis.js'
    import PaperDetail from '@/components/PaperDetail'

    export default {
        components: {
            PaperDetail
        },
        data() {
            return {
                paperId: Number(this.$route.query.id) || null,
                paperInfo: {
                    theory_test_paper_name: '',
                    theory_topic_data: {}
                }
            }
        },
        mounted() {
            this.getPaperDetail()
        },
        watch:{
            $route(route){
                this.paperId = route.query.id
                this.getPaperDetail()
            }
        },
        methods: {
            goBack() {
                this.$emit('changeShow', 1)
            },
            getPaperDetail() {
                let params = {
                    theory_test_paper_id: this.paperId
                }
                trainTheoryDetailsTeacher(params).then((res) => {
                    for (const key in res.data.theory_topic_data) {
                        res.data.theory_topic_data[key].list.forEach(item => {
                            if (!item.hasOwnProperty('student_answer')) {
                                this.$set(item, 'student_answer', [])
                                if (key === 'single_data' || key === 'judge_data' || key === 'multiple_data' || key === 'askAnswer_data') {
                                    item.student_answer = ['']
                                }
                                if (key === 'fillBlank_data') {
                                    for (let i = 0; i < item.options.length; i++) {
                                        let tmp = {
                                            name: ''
                                        }
                                        item.student_answer.push(tmp)
                                    }
                                }
                            }

                        })
                    }
                    this.paperInfo = res.data
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    .detail-container {
        height: 100%;
        display: flex;
        flex-direction: column;
        background: #ffffff;
    }

    .paper-title {
        font-size: 24px;
        line-height: 1;
        text-align: center;
        padding: 24px 0;
        border-bottom: 2px solid #E5E5E5;
        position: relative;
        margin-bottom: 30px;

        .backBtn {
            position: absolute;
            right: 20px;
            bottom: 18px;
        }
    }
</style>